import React from 'react';
import gsap from 'gsap';
import styled from 'styled-components';
import { device } from 'styles/theme';
import WrapperContainer from '@atoms/WrapperContainer';
import { useCursorContext } from 'context/CursorContext';

function ContactSection() {
  const { setSize } = useCursorContext();

  const tlRef = React.useRef<GSAPTimeline | null>(null);
  const spanRef2 = React.useRef(null);
  const spanRef3 = React.useRef(null);

  React.useEffect(() => {
    const tl = (tlRef.current = gsap.timeline({
      scrollTrigger: {
        trigger: spanRef2.current,
        start: 'top 80%',
      },
    }));

    const animation1 = gsap.to(spanRef2.current, {
      duration: 0.4,
      y: '-1.5vw',
    });
    const animation2 = gsap.to(spanRef3.current, {
      duration: 0.4,
      y: '-2.5vw',
    });
    tl.add(animation1, 0).add(animation2, 0);
  }, []);

  const onMouseEnter = () => {
    // check target hover to prevent jerking in animation

    setSize('large');
    tlRef.current?.reverse();
  };

  const onMouseLeave = () => {
    // check target hover to prevent jerking in animation

    setSize('small');
    tlRef.current?.play();
  };

  return (
    <WrapperContainer>
      <ContactContainer id="contact">
        <ContactQuestion>Want to create something together?</ContactQuestion>
        <ContactCTA
          href="mailto:gridin21@gmail.com"
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          <span>GET IN TOUCH</span>
          <span ref={spanRef2}>GET IN TOUCH</span>

          <span ref={spanRef3}>GET IN TOUCH</span>
        </ContactCTA>
      </ContactContainer>
    </WrapperContainer>
  );
}

const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ContactQuestion = styled.h4`
  width: 100%;
  font-size: 14vw;
  font-weight: 400;
  @media ${device.tablet} {
    width: 65%;
    font-size: 10vw;
  }
`;

const ContactCTA = styled.a`
  position: relative;
  align-self: flex-end;
  max-width: max-content;
  margin-top: 10vw;
  text-align: center;
  font-size: 10vw;
  font-weight: 600;
  z-index: 0;
  color: black;
  text-decoration: none;
  &:visited {
    color: black;
  }
  span {
    display: inline-block;
    position: relative;
    background-color: white;
    z-index: 3;
    line-height: 80%;
    &:nth-child(2) {
      position: absolute;
      top: 0.85vw;
      left: 0;
      z-index: 2;
    }
    &:nth-child(3) {
      position: absolute;
      top: 0.85vw;
      left: 0;
      z-index: 1;
    }
  }
  @media ${device.tablet} {
    font-size: 7vw;
  }
`;

export default ContactSection;
