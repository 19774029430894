import React from 'react';
import styled from 'styled-components';
import { device } from 'styles/theme';

type ContainerProps = {
  children: React.ReactNode;
  className?: string;
};

const Container = styled.div`
  padding: 0 12px;
  /* max-width: 1340px; */
  width: 100%;
  margin: 0 auto;
  @media ${device.tablet} {
    padding: 0 20px;
  }
`;

function WrapperContainer({ className, children }: ContainerProps): JSX.Element {
  return <Container className={className}>{children}</Container>;
}

export default WrapperContainer;
