import { useCursorContext } from 'context/CursorContext';
import useIsomorphicLayoutEffect from 'hooks/useIsomorphicLayoutEffect';
import React from 'react';
import styled from 'styled-components';

// TODO: use gsap for cursor move animation

function isTouchScreenCheck() {
  if (window) {
    return 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.maxTouchPoints > 0;
  }
  return false;
}

function CustomCursor() {
  const { size } = useCursorContext();
  const [isTouchScreen, setIsTouchScreen] = React.useState(false);
  const secondaryCursor = React.useRef<HTMLDivElement | null>(null);
  const positionRef = React.useRef({
    mouseX: 0,
    mouseY: 0,
    destinationX: 0,
    destinationY: 0,
    distanceX: 0,
    distanceY: 0,
    key: -1,
  });

  React.useEffect(() => {
    document.addEventListener('pointermove', event => {
      const { clientX, clientY } = event;
      const mouseX = clientX;
      const mouseY = clientY;

      if (secondaryCursor.current?.clientWidth && secondaryCursor.current?.clientHeight) {
        positionRef.current.mouseX = mouseX - secondaryCursor.current.clientWidth / 2;
        positionRef.current.mouseY = mouseY - secondaryCursor.current.clientHeight / 2;
      } else {
        positionRef.current.mouseX = mouseX;
        positionRef.current.mouseY = mouseY;
      }
    });
    return () => {
      null;
    };
  }, []);

  React.useEffect(() => {
    const followMouse = () => {
      positionRef.current.key = requestAnimationFrame(followMouse);
      const { mouseX, mouseY, destinationX, destinationY, distanceX, distanceY } =
        positionRef.current;

      if (!destinationX || !destinationY) {
        positionRef.current.destinationX = mouseX;
        positionRef.current.destinationY = mouseY;
      } else {
        positionRef.current.distanceX = (mouseX - destinationX) * 0.1;
        positionRef.current.distanceY = (mouseY - destinationY) * 0.1;
        if (
          Math.abs(positionRef.current.distanceX) + Math.abs(positionRef.current.distanceY) <
          0.1
        ) {
          positionRef.current.destinationX = mouseX;
          positionRef.current.destinationY = mouseY;
        } else {
          positionRef.current.destinationX += distanceX;
          positionRef.current.destinationY += distanceY;
        }
      }
      if (secondaryCursor && secondaryCursor.current) {
        secondaryCursor.current.style.transform = `translate3d(${destinationX}px, ${destinationY}px, 0)`;
      }
    };
    followMouse();
    return () => {
      null;
    };
  }, []);

  useIsomorphicLayoutEffect(() => {
    setIsTouchScreen(isTouchScreenCheck());
  }, []);

  return (
    <div>
      <SecondaryCursor touchScreen={isTouchScreen} ref={secondaryCursor} size={size} />
    </div>
  );
}

const SecondaryCursor = styled.div<{ size: 'small' | 'medium' | 'large'; touchScreen: boolean }>`
  display: ${({ touchScreen }) => (touchScreen ? 'none' : 'block')};
  position: fixed;
  z-index: 1000;
  border-radius: 50%;
  pointer-events: none;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
  background: transparent;
  border: 3px solid black;
  transition: width 0.25s ease-in-out, height 0.25s ease-in-out;
  transform-origin: center;
  width: ${({ size }) => {
    switch (size) {
      case 'small':
        return '1.8rem';
      case 'medium':
        return '6rem';
      case 'large':
        return '6rem';
      default:
        return '1.8rem';
    }
  }};
  height: ${({ size }) => {
    switch (size) {
      case 'small':
        return '1.8rem';
      case 'medium':
        return '6rem';
      case 'large':
        return '6rem';
      default:
        return '1.8rem';
    }
  }};
`;
export default CustomCursor;
