import { shouldNavigate } from '@utils/shouldNavigate';
import { triggerTransition } from '@utils/triggerTransition';
import { useTransitionContext } from 'context/TransitionContext';
import { GatsbyLinkProps, Link } from 'gatsby';
import React from 'react';

// https://www.polynique.com/web-development/extend-gatsby-link-in-typescript/
// https://github.com/gatsbyjs/gatsby/issues/34325
interface ITransitionLinkProps extends Omit<GatsbyLinkProps<Record<string, unknown>>, 'ref'> {
  children: React.ReactNode;
  exit?: any;
  enter?: any;
  trigger?: any;
  entry?: any;
}

const TransitionLink = React.forwardRef<HTMLAnchorElement, ITransitionLinkProps>(
  (
    {
      to,
      children,
      exit,
      entry,
      activeStyle,
      partiallyActive,
      style,
      className,
      activeClassName,
      state,
      onClick,
      trigger,
      replace,
      innerRef,
      preventScrollJump,
      ...rest
    },
    ref
  ) => {
    const { updateTransitionState, ...context } = useTransitionContext();
    const clickHandler: typeof onClick = event => {
      // If the user has set their browser or OS to prefers-reduced-motion
      // we should respect that.
      // if (disableAnimation) {
      //   return;
      // }

      const weShouldNavigate = shouldNavigate(event);
      if (weShouldNavigate) {
        triggerTransition({
          event,
          to,
          exit,
          entry,
          trigger,
          updateTransitionState,
          replace,
          preventScrollJump,
          linkState: state,
          ...context,
        });
      }
      if (typeof onClick === 'function') {
        // onClick(event, weShouldNavigate);
      }
    };
    return (
      <Link
        //  https://github.com/gatsbyjs/gatsby/issues/34325
        // based on this issue we need to use innerRef as GatsbyLink does not work with ref
        innerRef={ref || innerRef}
        to={to}
        onClick={clickHandler}
        {...rest}
      >
        {children}
      </Link>
    );
  }
);

TransitionLink.displayName = 'TransitionLink';

export default TransitionLink;
