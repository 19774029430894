import { useCursorContext } from 'context/CursorContext';
import gsap from 'gsap';
import React from 'react';
import styled from 'styled-components';
import TransitionLink from './TransitionLink';
import { useProjectPortal } from './TransitionPortal';

type CoverProps = {
  children: React.ReactNode;
  to: string;
  direction: 'left' | 'right' | 'top' | 'bottom';
};

function Cover({ children, to, direction }: CoverProps) {
  const Portal = useProjectPortal();
  const coverRef = React.useRef(null);
  const coverLoaderRef = React.useRef(null);
  const { setSize } = useCursorContext();
  // const getCoverEl = () => document.querySelector(`.tl-cover-el`);

  const horizontal = ({ node, props: { length: seconds }, direction }: any) => {
    const directionTo = direction === 'left' ? '-100%' : '100%';
    const directionFrom = direction === 'left' ? '100%' : '-100%';

    const wait = seconds / 6;
    const half = (seconds - wait) / 2;

    return gsap
      .timeline()
      .add('start')
      .set(coverRef.current, { y: 0, x: directionFrom, display: 'block' })
      .to(
        coverRef.current,
        {
          x: '0%',
          ease: 'power1.easeInOut',
          duration: half,
        },
        'start'
      )
      .from(
        coverLoaderRef.current,
        {
          width: '0%',
          x: direction === 'left' ? '-300%' : '300%',
          ease: 'power1.easeInOut',
          delay: 0,
          duration: half - 0,
          transformOrigin: '50% 100%',
        },
        'start'
      )
      .set(node, { opacity: 0 })
      .to(
        coverRef.current,
        {
          x: directionTo,
          ease: 'power1.easeInOut',
          duration: half,
        },
        `+=${wait}`
      )
      .to(
        coverLoaderRef.current,
        {
          width: '0%',
          x: direction === 'left' ? '300%' : '-300%',
          ease: 'power1.easeInOut',
          duration: half,
          transformOrigin: '50% 100%',
        },
        `<`
      );
  };

  const vertical = ({ node, props: { length: seconds }, direction }: any) => {
    const directionTo = direction === 'up' ? '-100%' : '100%';
    const directionFrom = direction === 'up' ? '100%' : '-100%';

    const wait = seconds / 6;
    const half = (seconds - wait) / 2;

    // const cover = getCoverEl();

    return gsap
      .timeline()
      .set(coverRef.current, { y: directionFrom })
      .to(coverRef.current, {
        y: '0%',
        ease: 'power1.easeInOut',
        duration: half,
      })
      .set(node, { opacity: 0 })
      .to(
        coverRef.current,
        {
          y: directionTo,
          ease: 'power1.easeIn',
          duration: half,
        },
        `+=${wait}`
      );
  };

  const moveInDirection = ({ props, direction, node }: any) => {
    if (direction === 'left' || direction === 'right') {
      return horizontal({ props, direction, node });
    }

    return vertical({ props, direction, node });
  };

  const onMouseEnter = () => {
    setSize('large');
  };

  const onMouseLeave = () => {
    setSize('small');
  };

  const length = 2;

  return (
    <>
      <TransitionLink
        to={to}
        exit={{
          length: length,
          trigger: ({ exit, node }: any) =>
            moveInDirection({
              props: exit,
              node,
              direction: direction,
            }),
        }}
        entry={{
          delay: length / 2,
        }}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {children}
      </TransitionLink>
      <Portal>
        <StyledCover ref={coverRef}>
          <CoverLoader ref={coverLoaderRef} />
        </StyledCover>
      </Portal>
    </>
  );
}

const StyledCover = styled.div`
  position: fixed;
  background: #19171c;
  top: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  transform: translateY(100%);
  z-index: 1000;
`;

const CoverLoader = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20vw;
  height: 20vh;
  /* aspect-ratio: 1/1; */
  background: #fff;
`;

export default Cover;
