import React from 'react';
import styled from 'styled-components';
import { theme } from 'styles/theme';
import Navbar from './molecules/Navbar';
import Footer from './molecules/Footer';
import useIsomorphicLayoutEffect from 'hooks/useIsomorphicLayoutEffect';
import { ScrollContextProvider, useScrollContext } from 'context/scrollContext';
// import gsap from 'gsap';
// import { ScrollTrigger, ScrollSmoother } from 'gsap/all';
// gsap.registerPlugin(ScrollTrigger, ScrollSmoother);

interface ILayoutProps {
  children: React.ReactNode;
}

const Layout = ({ children }: ILayoutProps) => {
  // const smootherRef = React.useRef<globalThis.ScrollSmoother | null>(null);
  // useIsomorphicLayoutEffect(() => {
  //   if (!smootherRef.current) {
  //     smootherRef.current = ScrollSmoother.create({
  //       wrapper: '#gatsby-focus-wrapper',
  //       content: '#smooth-content',
  //     });
  //   }
  // }, []);
  // useScrollContext();
  return (
    <ScrollContextProvider>
      <StyledLayout id="smooth-content">
        {/* Aligned to the top */}
        <LayoutContainer>
          <Navbar links={[{ url: '#contact', text: 'Contact' }]} />
          <MainContainer>
            {/* <Sidebar>
            <InnerSidebar>LEFT</InnerSidebar>
          </Sidebar> */}
            {children}
            {/* <Sidebar>
            <InnerSidebar>RIGHT</InnerSidebar>
          </Sidebar> */}
          </MainContainer>
        </LayoutContainer>
        {/* Aligned to the bottom */}
        <Footer />
      </StyledLayout>
    </ScrollContextProvider>
  );
};

const StyledLayout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  background-color: white;
  overflow: hidden;
`;

const LayoutContainer = styled.div`
  flex: 1 1 0%;
`;

const MainContainer = styled.main`
  display: flex;
  flex-direction: column;
  /* display: grid;
  grid-template-columns: 85px 1fr 85px; */
`;

const InnerSidebar = styled.div`
  position: fixed;
`;

const Sidebar = styled.aside`
  position: relative;
  width: 85;
  background-color: gray;
  div {
    position: fixed;
  }
`;

export default Layout;
