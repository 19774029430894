import WrapperContainer from '@atoms/WrapperContainer';
import { useCursorContext } from 'context/CursorContext';
import { useScrollContext } from 'context/scrollContext';
import React from 'react';
import styled from 'styled-components';
import { device } from 'styles/theme';

function Footer() {
  const { smoother } = useScrollContext();
  const { setSize } = useCursorContext();

  const onMouseEnter = () => {
    setSize('large');
  };

  const onMouseLeave = () => {
    setSize('small');
  };
  return (
    <WrapperContainer>
      <StyledFooter>
        <span>Sergey Gridin</span>
        <span
          onClick={() => {
            smoother?.scrollTo(0, true);
          }}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          Back to top
        </span>
        <Socials>
          <SocialLink
            href="https://twitter.com/Gridin21"
            rel="noreferrer"
            target="_blank"
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          >
            Twitter
          </SocialLink>
          <SocialLink
            href="https://github.com/SergeyGridin"
            rel="noreferrer"
            target="_blank"
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          >
            Github
          </SocialLink>
          <SocialLink
            href="https://www.instagram.com/thesergeygridin"
            rel="noreferrer"
            target="_blank"
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          >
            Instagram
          </SocialLink>
        </Socials>
      </StyledFooter>
    </WrapperContainer>
  );
}

const StyledFooter = styled.footer`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  width: 100%;
  margin-top: 100px;
  padding: 32px 0px;
  font-size: max(1.1vw, 18px);
  @media ${device.mobileXL} {
    flex-direction: row;
    margin-top: 150px;
  }
`;

const Socials = styled.div`
  display: flex;
  gap: 32px;
`;

const SocialLink = styled.a`
  color: black;
  text-decoration: none;
  &:visited {
    color: black;
  }
`;
export default Footer;
