import React from 'react';
import gsap from 'gsap';
import { ScrollTrigger, ScrollSmoother } from 'gsap/all';
import useIsomorphicLayoutEffect from 'hooks/useIsomorphicLayoutEffect';
gsap.registerPlugin(ScrollTrigger, ScrollSmoother);

export type IScrollContext = {
  smoother: globalThis.ScrollSmoother | null;
};

interface ScrollContextProviderProps {
  children: React.ReactNode;
}

const ScrollContext = React.createContext<IScrollContext>({} as IScrollContext);
ScrollContext.displayName = 'ScrollContext';

export function ScrollContextProvider({ children }: ScrollContextProviderProps) {
  const [smoother, setSmoother] = React.useState<globalThis.ScrollSmoother | null>(null);

  useIsomorphicLayoutEffect(() => {
    // TODO: why is this rerendering?
    if (!smoother) {
      const smootherObj = ScrollSmoother.create({
        wrapper: '#gatsby-focus-wrapper',
        content: '#smooth-content',
      });

      setSmoother(smootherObj);
    }
  }, []);

  const ctx = {
    smoother,
  };

  return <ScrollContext.Provider value={ctx}>{children}</ScrollContext.Provider>;
}

export const useScrollContext = () => React.useContext(ScrollContext);
