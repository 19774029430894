import { navigate, withPrefix } from 'gatsby';
import { setTimeout } from 'requestanimationframe-timer';
import getMs from './getMs';

function getPagesPromises() {
  let exitResolve;
  const exitPromise = new Promise(resolve => {
    exitResolve = resolve;
  });

  let entryResolve;
  const entryPromise = new Promise(resolve => {
    entryResolve = resolve;
  });

  return {
    triggerResolve: {
      entry: entryResolve,
      exit: exitResolve,
    },
    pages: {
      exit: exitPromise,
      entry: entryPromise,
    },
  };
}

export const triggerTransition = ({
  to,
  event = null,
  exit = {},
  entry = {},
  inTransition,
  pages,
  trigger,
  updateTransitionState,
  linkState,
  replace,
  preventScrollJump,
}: any) => {
  // implemet this
  // https://github.com/TylerBarnes/gatsby-plugin-transition-link/blob/master/src/utils/triggerTransition.js
  if (event) {
    event.persist();
    event.preventDefault();
  }

  if (inTransition) return false;

  let hash: any;

  // handle anchor links to ID's
  if (to.includes('#')) {
    const toSplit = to.split('#');
    to = toSplit[0];
    hash = toSplit[1];
  }

  // these globals prevent the back button from being pressed during a transition as that can have unexpected results
  (window as any).__tl_inTransition = true;
  // window.__tl_desiredPathname = withPrefix(to);

  updateTransitionState((prevState: any) => ({
    ...prevState,
    inTransition: true,
    exitDelay: 0,
    exitLength: 0,
    appearAfter: 0,
    exitState: {},
  }));

  if (trigger && typeof trigger === 'function') {
    trigger(pages);
  }

  const {
    length: exitLength = 0,
    delay: exitDelay = 0,
    state: exitState = {},
    trigger: exitTrigger = () => null,
  } = exit;
  const {
    length: entryLength = 1, // this allows scrollposition to be reset when there is no transition.
    delay: entryDelay = 0,
    state: entryState = {},
    trigger: entryTrigger = () => null,
    appearAfter = 0,
  } = entry;

  updateTransitionState((prevState: any) => ({
    ...prevState,
    entryLength: entryLength,
    entryDelay: entryDelay,
    exitLength: exitLength,
    exitDelay: exitDelay,
    entryProps: entry,
    exitProps: exit,
    appearAfter,
    preventScrollJump,
    exitTrigger: (exit: any, node: any, e: any) => exitTrigger(exit, node, e),
    entryTrigger: (entry: any, node: any, e: any) => entryTrigger(entry, node, e),
    e: event,
  }));
  // after exitDelay
  setTimeout(() => {
    navigate(to, {
      state: {
        ...linkState,
      },
      replace,
    });

    updateTransitionState((prevState: any) => ({
      ...prevState,
      exitState: exitState,
      hash,
    }));
  }, getMs(exitDelay));

  setTimeout(() => {
    // wait for entryDelay before we add entry state
    updateTransitionState((prevState: any) => ({ ...prevState, entryState: entryState }));
  }, getMs(exitDelay + entryDelay));

  // reset entry animation times so they dont apply when using browser back/forward.
  //  this will be replaced with a better solution in the future
  setTimeout(
    () =>
      updateTransitionState((prevState: any) => ({
        ...prevState,
        entryDelay: 0,
        appearAfter: 0,
        entryLength: 0,
      })),
    getMs(exitDelay + entryDelay + entryLength)
  );

  const finalResetSeconds = exitDelay + Math.max(exitLength, entryDelay + entryLength);

  // reset exit animation times so they dont apply when using browser back/forward.
  //  this will be replaced with a better solution in the future
  setTimeout(() => {
    // these globals prevent the back button from being pressed during a transition as that can have unexpected results
    (window as any).__tl_inTransition = false;
    // window.__tl_desiredPathname = false;
    // window.__tl_back_button_pressed = false;

    updateTransitionState((prevState: any) => ({
      ...prevState,
      exitDelay: 0,
      exitLength: 0,
      // Once all animation is finished, it's safe to start a new animation since we're no longer inTransition.
      inTransition: false,
      // create new page promises for the trigger prop
      ...getPagesPromises(),
    }));
  }, getMs(finalResetSeconds) + 1);
};
