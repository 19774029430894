import React from 'react';
import styled from 'styled-components';
import WrapperContainer from '@atoms/WrapperContainer';
import Text from '@atoms/Text';
import Cover from 'components/animations/Cover';
import { useScrollContext } from 'context/scrollContext';
import { useCursorContext } from 'context/CursorContext';

type NavbarProps = {
  links: { url: string; text: string }[];
};

function Navbar({ links }: NavbarProps) {
  const { smoother } = useScrollContext();
  const { setSize } = useCursorContext();

  const onMouseEnter = () => {
    setSize('large');
  };

  const onMouseLeave = () => {
    setSize('small');
  };

  return (
    <StyledNavbar>
      <WrapperContainer>
        <InnerNavbar>
          <Cover to="/" direction="right">
            <Text as="span" variant="paragraph">
              Home
            </Text>
          </Cover>
          {links.map(link => {
            return (
              <a
                key={link.url}
                href={link.url}
                onClick={e => {
                  e.preventDefault();
                  smoother?.scrollTo('#contact', true);
                }}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
              >
                <Text as="span" variant="paragraph">
                  {link.text}
                </Text>
              </a>
            );
          })}
        </InnerNavbar>
      </WrapperContainer>
    </StyledNavbar>
  );
}

const StyledNavbar = styled.nav`
  width: 100vw;
  /* border-bottom: 1px solid black; */

  ${Text} {
    color: black;
  }
`;

const InnerNavbar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  & ${Text} {
    font-size: max(1.1vw, 18px);
  }
`;

export default Navbar;
