import React from 'react';
import ReactDOM from 'react-dom';

const portalRoot = typeof document !== `undefined` ? document.body : false;
const isBrowser = typeof document !== `undefined` ? true : false;

// create portal that will be appended on mount OR
// create an element in the gatsby-browsr api and use it as portal (easier)
// function TransitionPortal() {
//   const elRef = typeof document !== `undefined` ? document.createElement('div') : null;

//   React.useEffect(() => {
//     elRef && portalRoot && portalRoot.appendChild(elRef);

//     return () => {
//       elRef && portalRoot && portalRoot.removeChild(elRef);
//     };
//   });
//   return <div>TransitionPortal</div>;
// }

// const PortalContainer = (props: { children: React.ReactNode }) => {
//   return (
//     <div
//       className="gatsby-plugin-transition-link-portal"
//       style={{
//         position: 'fixed',
//         top: 0,
//         left: 0,
//         zIndex: 1000,
//       }}
//     >
//       {props.children}
//     </div>
//   );
// };

export const useProjectPortal = () => {
  const [portal, setPortal] = React.useState<ReturnType<typeof createPortal>>({
    render: () => null as any,
    remove: () => null as any,
  });

  const portalRef = React.useRef(isBrowser ? document.createElement('div') : null);

  React.useEffect(() => {
    if (isBrowser && !portalRef.current) portalRef.current = document.createElement('div');
    portalRef.current && portalRoot && portalRoot.appendChild(portalRef.current);
  }, [isBrowser, portalRef.current]);

  React.useEffect(() => {
    if (portalRef.current) portal.remove();
    const newPortal = createPortal(portalRef.current);
    setPortal(newPortal);
    // return () => newPortal.remove(el); we will handle removal ourselves
  }, [portalRef.current]);

  const createPortal = React.useCallback(el => {
    const Portal = ({ children }: { children: React.ReactNode }) =>
      ReactDOM.createPortal(children, el);
    const remove = () => ReactDOM.unmountComponentAtNode(el);
    return { render: Portal, remove };
  }, []);

  return portal.render;
};
